<template>
    <div class="input-group-container toggle-input has-value">
        <label v-if="label" class="input-group-title">
            {{ label }}
        </label>
        <div class="input-group">
            <div class="toggle tooltip-handle">
                <input
                    :id="id ?? name"
                    :name="name"
                    :checked="value"
                    class="toggle-cb"
                    type="checkbox"
                    @change="(event) => emit('update:value', (event.target as HTMLInputElement).checked)"
                />
                <label :for="id ?? name" class="toggle-label">
                    <span class="toggle-inner"></span>
                    <span class="toggle-switch"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
defineProps({
    id: { type: String },
    name: { type: String },
    label: { type: String },
    value: { type: Boolean, default: false },
});

const emit = defineEmits(['update:value']);
</script>

<style lang="postcss" scoped>
.toggle-input {
    @apply max-w-[150px];
}
.toggle {
    @apply relative w-full h-14;
}
.toggle input[type='checkbox'] {
    @apply hidden;
}
.toggle-label {
    @apply block overflow-hidden cursor-pointer h-full rounded-md;
}
.toggle-inner {
    @apply w-[200%] h-full ml-[-100%] transition-[margin] duration-[0.3s] ease-[ease-in] delay-[0s] flex before:content-['On'] before:text-white after:content-['Off'];
}
.toggle-inner::before,
.toggle-inner::after {
    @apply flex items-center bg-[#eff4f9] justify-center w-6/12 h-full leading-6 text-[#5a5e6b] text-base shadow-[inset_0_3px_5px_rgba(0,0,0,0.125)] box-border p-0;
}
.toggle-inner::before {
    @apply bg-[#34b4f5] text-white;
}
.toggle-switch {
    @apply w-[15px] bg-white border rounded absolute right-[calc(100%_-_15px)] transition-all duration-[0.3s] ease-[ease-in] delay-[0s] border-solid border-[rgba(0,0,0,0.15)] inset-y-0;
}
.toggle-cb:checked + .toggle-label .toggle-inner {
    @apply ml-0;
}
.toggle-cb:checked + .toggle-label .toggle-switch {
    @apply right-0;
}
</style>
